//スムーススクロール
$(function() {
    const headerHeight = $('header').outerHeight(); // ヘッダーの高さを取得
  
    $('a[href^="#anchor-"]').on('click', function(event) {
      event.preventDefault();
  
      const target = $(this.hash);
      if (target.length) {
        
        const targetOffset = target.offset().top - headerHeight;
  
        $('html, body').animate({ scrollTop: targetOffset }, 1000);
      }
    });
  });
  

//ハンバーガーメニュー
$(function() {
  $('.hamburger').click(function() {
      $(this).toggleClass('active');
 
      if ($(this).hasClass('active')) {
          $('.menu').addClass('active');
      } else {
          $('.menu').removeClass('active');
      }
  });
  $('.menu-item a').click(function() {
    $('.hamburger').removeClass('active');
    $('.menu').removeClass('active');
  });
  $('.header_button').click(function() {
    $('.hamburger').removeClass('active');
    $('.menu').removeClass('active');
  });
});


//モーダル
$(document).ready(function() {
  $(".js-modal").modaal({
    start_open: true,
    hide_close: 'true',

    after_open: function() {
      $('#js-page-overlay').addClass('hidden');
    }
  });

  $('.js-modal-close').on('click', function () {
      $('.js-modal').modaal('close');
      $('.js-modal-note').modaal('close');
  });

  $('.js-modal-note').on('click', function () {
      $(".js-modal-note").modaal({
          hide_close: 'true'
      });
  });
});


//アコーディオン
$(function () {
    $('.js-accordion').click(function () {
        $(this).toggleClass('open');
        $(this).next('.js-accordion_detail').slideToggle();
    });
});


//ランキングごとのプレゼント箇所
$(document).ready(function() {
    const rankingAreas = $('.js-ranking-area');
    const cardInners = $('.card-inner');

    rankingAreas.on('click', function() {
      const targetTab = $(this).data('tab');

      cardInners.removeClass('is-active');
      $('.card-inner[data-tab="' + targetTab + '"]').addClass('is-active');

      rankingAreas.removeClass('current');
      $(this).addClass('current');
    });
  });

